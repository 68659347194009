import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import Title from "../components/Headings/Title"
import styled from "styled-components"

const Pagination = styled.div`
  height: auto;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  > span {
    font-size: 18px;
    font-weight: 600;

    div {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Resume = () => {
  const data = useStaticQuery(
    graphql`
      {
        Resume: allFile(
          filter: {
            relativeDirectory: { eq: "resume" }
            extension: { eq: "html" }
          }
        ) {
          edges {
            node {
              name
              relativePath
            }
          }
        }
      }
    `
  )
  return (
    <Layout>
      <Seo title="Gallery" />
      <section className="resume-section">
        <Title>Resume</Title>
        <div className="container py-5">
          <Pagination>
            {data.Resume.edges.map(({ node }) => {
              return (
                <span
                  className="card border-primary text-center shadow p-2"
                  style={{
                    backgroundColor:
                      "#" + Math.random().toString(16).slice(-6) + "10",
                  }}
                  key={node.name}
                >
                  <a
                    style={{
                      borderRadius: "4px",
                    }}
                    className="card-body bg-primary text-light text-decoration-none shadow"
                    href={`/${node.relativePath}`}
                  >
                    <div>{node.name}</div>
                  </a>
                </span>
              )
            })}
          </Pagination>
        </div>
      </section>
    </Layout>
  )
}

export default Resume
